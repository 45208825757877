@mixin font($font-family, $font-file) {
  @font-face {
    font-family: $font-family;
    src: url($font-file) format('truetype');
    font-display: swap;
  }
}

@include font ('PP Right Gothic Medium', '/wp-content/themes/fenix4/dist/font/PPRightGothic-Medium.otf');
@include font ('PP Right Gothic', '/wp-content/themes/fenix4/dist/font/PPRightGothic.otf');
@include font ('Inter Bold', '/wp-content/themes/fenix4/dist/font/Inter-Bold.ttf');
@include font ('Inter', '/wp-content/themes/fenix4/dist/font/Inter-Regular.ttf');

a {
  font-family: 'open_sansregular', sans-serif;
  font-size: 16px;
}

p,
ul {
  color: #1E1F25;
  font-family: 'open_sansregular', sans-serif;
  font-size: 16px;
}

ol {
  color: #1E1F25;
  font-family: 'open_sansregular', sans-serif;
  font-size: 17px;
}

.fullwidth-container--prefooter {
  border-top: solid 1px #f0f0f0;
}

.legal-service-content__h1 {
  margin-bottom: 100px;
  color: #1E1F25;
  font-size: 60px;
  font-weight: 900;
  letter-spacing: 0.5px;
  line-height: 70px;
}

.legal-service-content {
  text-align: center;
  max-width: 1095px;
  margin: 100px auto;
}

.fullwidth-container--result .legal-service-content {
  text-align: left;
  max-width: 1095px;
  margin: 100px auto;
}

.legal-service-content__inherit {
  margin-top: 25px;
}

.legal-service-content p.legal-service-content__text {
  color: #1E1F25;
  font-family: 'open_sansregular', sans-serif;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 0.5rem;
}

ul#tabList li {
  color: #1E1F25;
  font-family: 'open_sansregular', sans-serif;
  font-size: 17px;
  line-height: 27px;
  margin-bottom: 1rem;
  text-align: left;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
}

ul#tabList {
  padding-left: 0;
}

.legal-service-content__thumb {
  width: 75px;
}

.btn.legal-service-content__outline {
  font-size: 22px;
  padding: 21px 40px 21px 40px;
}

.btn.legal-service-content__blue {
  font-size: 22px;
  padding: 21px 40px 21px 40px;
}

.legal-service-content__inherit .legal-service-content__thumb {
  margin-right: 20px;
}

.legal-service-content__button {
  text-align: center;
}

.legal-service-content__phone {
  text-align: center;
  margin-top: 30px;
}

.legal-service-content__images {
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.legal-service-content__image {
  width: 250px;
  position: relative;
  height: 332px;
  object-fit: cover;
}

.legal-service-content__help {
  margin-bottom: 30px;
}

.legal-service-content__wrapper img.legal-service-content__image {
  height: auto;
}

.legal-service-content__slide {
  margin: 0 10px;
  border: 1px solid #ededed;
  padding-bottom: 10px;
}

.legal-service-content__logo .legal-service-content__slide {
  padding: 10px;
}

.legal-service-content__logo .legal-service-content__slide {
  display: flex;
  align-items: center;
}

.legal-service-content__title {
  display: block;
  text-align: center;
  font-size: 14px;
  margin-top: .5em;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.legal-service-content__logo {
  display: flex;
  justify-content: space-between;
}

.legal-service-content__collaborate {
  text-align: center;
  margin-bottom: 50px;
  font-size: 30px;
}

.tab__row {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 34px 34px;
  background: rgba(0,0,0,.05);
}

.tab__title {
  margin-bottom: 30px;
  font-size: 26px;
}

.tab__wide-col .tab__title {
  text-align: left;
}

.legal-service-content__step {
  margin: 0 10px;
}

.tab__wide-col {
  width: 500px;
}

.tab__thin-col {
  text-align: left;
  padding-left: 90px;
  width: 380px;
}

.tab__thin-col .legal-service-content__step {
  margin: 0 0 30px;
}

.tab__email-notice {
  text-align: left;
  opacity: .8;
  margin: 17px 0;
  padding: 0;
  font-size: 14px !important;
  line-height: normal;
  color: #272727;
}

.tab__input {
  width: 250px;
  height: 100%;
  padding: 1em;
  background: #fff;
  border: 1px solid rgba(0,0,0,.12);
  font-family: inherit;
  font-size: 15px;
  line-height: normal;
  outline: none;
  margin-right: 10px;
  border-radius: 5px;
}

figure.figure.figure--image {
  width: 100%;
}

.tab__input-wrapper {
  display: flex;
}

.tab__submit {
  border: 2px solid #272727 !important;
  font-family: inherit;
  font-size: 14px;
  padding: 1em 2.5em;
  line-height: normal;
  font-weight: normal;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
}

.figure--image img {
  width: 100%;
}

figure.figure.figure--image {
  width: 100%;
}

.fancybox-slide--image .fancybox-content {
  position: relative;
  display: flex;
  align-items: center;
}

.fancybox-image {
  position: relative;
  width: 1200px!important;
  height: auto;
  margin: 0 auto;
  max-width: 100%;
}

button.focus, button:focus, button:active:focus {
  box-shadow: unset!important;
}

@media (max-width: 991px){
  .legal-service-content__logo .legal-service-content__image {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .tab__wide-col {
    width: 100%;
  }

  .tab__thin-col .legal-service-content__step {
    margin: 0 10px 0 0;
  }

  .tab__row{
    flex-wrap: wrap;
  }

  .tab__wide-col {
    width: 100%;
  }

  .tab__thin-col {
    padding-left: 0;
    width: 100%;
  }
}

@media (max-width: 575px) and (min-width: 1px) {
  .legal-service-content__h1 {
    font-size: 28px;
    letter-spacing: 0;
    line-height: 38px;
    margin-bottom: 50px;
  }

  .btn.legal-service-content__outline {
    font-size: 17px;
    line-height: 22px;
    padding: 13px 40px 13px 40px;
  }

  .btn.legal-service-content__blue {
    font-size: 17px;
    line-height: 22px;
    padding: 13px 40px 13px 40px;
  }

  .legal-service-content__h4 {
    display: flex;
    flex-direction: column;
    max-width: 200px;
    margin: 0 auto 10px;
  }

  .legal-service-content__images .legal-service-content__slide {
    flex-basis: 50%;
  }

  .legal-service-content__logo {
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .legal-service-content__logo .legal-service-content__slide:last-child {
    margin-bottom: 0;
    width: 100%;
  }

  .legal-service-content__wrapper .legal-service-content__image {
    height: auto;
    width: 100%;
  }

  .legal-service-content__images .legal-service-content__image {
    width: 100%!important;
    position: relative;
    height: 280px;
    object-fit: cover;
  }

  .tab__thin-col {
    text-align: center;
  }

  .tab__thin-col .legal-service-content__step,
  .tab__thin-col .legal-service-content__outline {
    margin: 0 auto 20px auto;
    width: 200px;
  }

  .tab__input-wrapper {
    flex-direction: column;
  }

  .tab__input {
    width: 100%;
    margin-bottom: 20px;
    padding: 1em;
  }

  .tab__thin-col {
    display: flex;
    flex-direction: column;
  }
}

/* end ARV */

.fullwidth-container--prefooter {
  border-top: solid 1px #f0f0f0;
}

.legal-service-content__h1 {
  margin-bottom: 100px;
  color: #1E1F25;
  font-size: 60px;
  font-weight: 900;
  letter-spacing: 0.5px;
  line-height: 70px;
}

.legal-service-content {
  text-align: center;
  max-width: 1095px;
  margin: 100px auto;
}

.legal-service-content__inherit {
  margin-top: 25px;
}

.legal-service-content p.legal-service-content__text {
  color: #1E1F25;
  font-family: 'open_sansregular', sans-serif;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 0.5rem;
}

ul#tabList li {
  color: #1E1F25;
  font-family: 'open_sansregular', sans-serif;
  font-size: 17px;
  line-height: 27px;
  margin-bottom: 1rem;
  text-align: left;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
}

ul#tabList {
  padding-left: 0;
}

.legal-service-content__thumb {
  width: 75px;
}

.btn.legal-service-content__outline {
  position: relative;
  color: #002733;
  font-family: 'open_sansbold', sans-serif;
  font-size: 22px;
  font-weight: bold;
  line-height: 22px;
  background-color: #FFFFFF;
  padding: 21px 40px 21px 40px;
  border: 2px solid #002733;
  border-radius: 63px;
  white-space: normal;
  letter-spacing: .05em;
}

.btn.legal-service-content__outline:hover {
  border: 2px solid #0069D9!important;
  background-color: #0069D9!important;
  color: #fff !important;
}

.legal-service-content__inherit .legal-service-content__thumb {
  margin-right: 20px;
}

.legal-service-content__button {
  text-align: center;
}

.legal-service-content__phone {
  text-align: center;
  margin-top: 30px;
}

.legal-service-content__images {
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.legal-service-content__image {
  width: 100%;
  max-width: 250px;
  position: relative;
  height: 332px;
  object-fit: cover;
}

.legal-service-content__help {
  margin-bottom: 30px;
}

.legal-service-content__wrapper img.legal-service-content__image {
  height: auto;
}

.legal-service-content__slide {
  margin: 0 10px;
  border: 1px solid #ededed;
  padding-bottom: 10px;
}

.legal-service-content__logo .legal-service-content__slide {
  padding: 10px;
}

.legal-service-content__logo .legal-service-content__slide {
  display: flex;
  align-items: center;
  justify-content: center;
}

.legal-service-content__title {
  display: block;
  text-align: center;
  font-size: 14px;
  margin-top: .5em;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.legal-service-content__logo {
  display: flex;
  justify-content: space-between;
}

.legal-service-content__collaborate {
  text-align: center;
  margin-bottom: 50px;
  font-size: 30px;
}

.tab__row {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 34px 34px;
  background: rgba(0,0,0,.05);
}

.tab__title {
  margin-bottom: 30px;
  font-size: 26px;
}

.tab__wide-col .tab__title {
  text-align: left;
}

.legal-service-content__step {
  margin: 0 10px;
}

.tab__wide-col {
  width: 500px;
}

.tab__thin-col {
  text-align: left;
  padding-left: 90px;
  width: 380px;
}

.tab__thin-col .legal-service-content__step {
  margin: 0 0 30px;
}

.tab__email-notice {
  text-align: left;
  margin-top: 15px;
  opacity: .8;
  margin: 17px 0;
  padding: 0;
  font-size: 14px !important;
  line-height: normal;
  color: #272727;
}

.tab__input {
  width: 250px;
  padding: 1em;
  background: #fff;
  border: 1px solid rgba(0,0,0,.12);
  font-family: inherit;
  font-size: 15px;
  line-height: normal;
  outline: none;
  margin-right: 10px;
  border-radius: 5px;
}

.tab__input.error {
  color: #000!important;
}

label#inputEmail-error {
  margin-left: 15px;
}

.tab__input-wrapper {
  display: flex;
}

.tab__submit {
  border: 2px solid #272727 !important;
  font-family: inherit;
  font-size: 14px;
  padding: 1em 2.5em;
  line-height: normal;
  font-weight: normal;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
}

button.focus, button:focus, button:active:focus {
  box-shadow: unset!important;
}

@media (max-width: 768px){
  .tab__wide-col {
    width: 100%;
  }

  .tab__thin-col .legal-service-content__step {
    margin: 0 10px 0 0;
  }

  .tab__row{
    flex-wrap: wrap;
  }

  .tab__wide-col {
    width: 100%;
  }

  .tab__thin-col {
    padding-left: 0;
    width: 100%;
  }
}

@media (max-width: 575px) and (min-width: 1px) {
  .legal-service-content__h1 {
    font-size: 28px;
    letter-spacing: 0;
    line-height: 38px;
    margin-bottom: 50px;
  }

  .btn.legal-service-content__outline {
    font-size: 17px;
    line-height: 22px;
    padding: 13px 40px 13px 40px;
  }

  .legal-service-content__h4 {
    display: flex;
    flex-direction: column;
    max-width: 200px;
    margin: 0 auto 10px;
  }

  .legal-service-content__images .legal-service-content__slide {
    flex-basis: 50%;
  }

  .legal-service-content__logo {
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .legal-service-content__logo .legal-service-content__slide {
    margin-bottom: 50px;
    width: 100%;
  }

  .legal-service-content__logo .legal-service-content__slide:last-child {
    margin-bottom: 0;
    width: 100%;
  }

  .legal-service-content__wrapper .legal-service-content__image {
    height: auto;
    width: 100%;
    max-width: 100%;
  }

  .legal-service-content__images .legal-service-content__image {
    width: 100%;
    position: relative;
    height: 280px;
    object-fit: cover;
  }

  .tab__thin-col {
    text-align: center;
  }

  .tab__thin-col .legal-service-content__step,
  .tab__thin-col .legal-service-content__outline {
    margin: 0 auto 20px auto;
    width: 200px;
  }

  .tab__input-wrapper {
    flex-direction: column;
  }

  .tab__input {
    width: 100%;
    margin-bottom: 20px;
    padding: 1em;
  }

  .tab__thin-col {
    display: flex;
    flex-direction: column;
  }
}

.email-error {
  border: 1px solid red !important;
}

.quiz-swipper-container {
  position: relative;
  max-width: 685px;
  overflow: visible;
  margin-bottom: 55px;
  @media (max-width: 525px){
    margin-bottom: 35px;
  }
}

.quiz-swipper-wrapper {
  position: relative;
}

.quiz-swipper-slide {
  padding: 86px 76px;
  isolation: isolate;
  height: 600px;
  background: #FFFFFF;
  box-shadow: 0px 18px 21px 11px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  max-width: 685px;

  .go_prev_slide {
    position: absolute;
    left: 2%;
    top: 50%;
    width: 75px;
    height: 75px;
    transform: translate(-50%,-50%);
    background: #FFFFFF;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    cursor: pointer;
    border: none;

    &:after {
      content: "";
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='15' height='9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.167 1.5l6 6 6-6' stroke='%23002733' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
      width: 35px;
      height: 20px;
      top: 50%;
      left: 50%;
      background-size: cover;
      display: block;
      transform: translate(-50%,-50%) rotate(-270deg);
      position: absolute;
      background-repeat: no-repeat;
    }
  }

  .go_next_slide {
    width: 75px;
    height: 75px;
    right: -5%;
    transform: translateY(-50%);
    top: 50%;
    background: #D6A600;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    position: absolute;
    cursor: pointer;
    border: none;
    //display: none;
    &:after {
      content: "";
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg width='15' height='9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.167 1.5l6 6 6-6' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
      width: 35px;
      height: 20px;
      top: 50%;
      left: 50%;
      background-size: cover;
      display: block;
      transform: translate(-50%,-50%) rotate(-90deg);
      position: absolute;
      background-repeat: no-repeat;
    }
  }
}

.quiz-swipper-content{
  width: 100%;
}

.step_back .go_next_slide{
  display: block;
}

.quiz-swipper-slide.quiz-first_slide {
  background: transparent;
  box-shadow: none;
  padding: 0;
}

.quiz-swipper-slide.quiz-first_slide .quiz-swipper-content {
  text-align: left;
  max-width: initial;
}

.first_slide-title {
  font-family: 'PP Right Gothic Medium', 'rawline';
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  color: #FFFFFF;
  text-align: left;
  margin-bottom: 40px;
  max-width: 595px;
}

.first_slide-subtitle {
  font-family: 'Inter', 'rawline';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #FFFFFF;
  max-width: 595px;
}

.first_slide-button {
  background: #FFFFFF;
  border-radius: 63px;
  padding: 12px 20px 12px 30px;
  font-family: 'Inter', 'rawline';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #002733;
  cursor: pointer;
  border-width: 0;

  img {
    position: relative;
    top: -2px;
  }
}

.quiz-swipper-slide.last_step {
  height: auto;
}

.go_next_slide.disabled,
.go_prev_slide.disabled {
  pointer-events: none;
  display: none;
}

.go_next_slide.disabled:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  border-radius: 50%;
  background: rgba(0,39,51, .65)
}

.quiz-swipper-step {
  position: absolute;
  display: flex;
  align-items: center;
  left: 32px;
  top: 16px;
  font-family: 'Inter Bold', 'rawline';
  font-style: normal;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: #D6A600;
}

.quiz-swipper-step div:nth-child(1) {
  font-size: 35px;
  font-family: 'PP Right Gothic Medium', 'rawline';
  font-style: normal;
  font-weight: 500;
  margin-right: 18px;
}

.quiz-swipper-step div .quiz-swipper-step-number .quiz-swipper-step-number-big {
  font-family: 'PP Right Gothic Medium', 'rawline';
  font-style: normal;
  font-weight: 500;
  font-size: 64px;
  line-height: 100%;
}

.quiz-swipper-content-heading {
  font-family: 'Inter Bold', 'rawline';
  font-style: normal;
  font-size: 32px;
  line-height: 110%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #002733;
  margin-bottom: 30px;
}

.quiz-swipper-content-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  button {
    font-family: 'PP Right Gothic Medium', 'rawline';
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.02em;
    background: #FFFFFF;
    color: #000000;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    padding: 22px 80px;
    margin: 0;
    border: 4px solid #fff;

    @media (max-width: 768px){
      font-size: 30px;
      padding: 15px 45px;
    }

    @media (max-width: 525px){
      padding: 10px 30px;
    }
  }

  @media (max-width: 350px){
    flex-direction: column;
    button{
      &:first-of-type{
        margin-bottom: 10px;
      }
    }
  }
}

.quiz-swipper.tab__input {
  display: block;
  padding: 1em;
  background: #fff;
  outline: none;
  border: 2px solid #002733;
  border-radius: 47px;
  font-family: 'Inter','rawline';
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  height: 48px;

}
.quiz-swipper-tab__input-wrapper{
  display: block;
  width: 100%;
  position: relative;
  &:before{
    display: block;
    content: "";
    width: 21px;
    height: 15px;
    background: url('/wp-content/themes/fenix4/dist/images/quiz_images/slider_email_icon.svg') no-repeat 50%;
    background-size: cover;
    position: absolute;
    right: -12px;
    left: 20px;
    top: 17px;
  }
}

.tabForm-required-hint{
  font-family: 'Inter', 'rawline';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  align-items: center;
  letter-spacing: -0.02em;
  color: #002733;
  display: block;
  text-align: left;
  align-self: baseline;
  margin-bottom: 10px;
  position: relative;
  &:before{
    display: block;
    content: "";
    width: 6px;
    height: 6px;
    background: url('/wp-content/themes/fenix4/dist/images/quiz_images/slider_required_icon.svg') no-repeat center center;
    background-size: cover;
    position: absolute;
    right: -9px;
  }
}

#tabForm.quiz-swipper-content-buttons {
  flex-flow: column;
  width: 100%;
  max-width: 100%;
  justify-content: center;
  align-items: center;
}

.quiz-swipper-content-buttons button:nth-child(1) {
  color: #fff;
}

.quiz-swipper-content-buttons button:nth-child(1) {
  color: #002733;
}

.quiz-swipper-content-buttons button:hover {
  border: 4px solid #D6A600;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  color: #D6A600;
}

.quiz-swipper-content-buttons button.button_active {
  background: #D6A600;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.1);
  color: #FFFFFF;
  border: 4px solid #D6A600;
}

.quiz-swipper-content-buttons button.questionList_confirm,
.quiz-swipper-content-buttons button.questionList_reload {
  font-size: 20px;
  padding: 22px 50px;
}

.quiz-swipper-progress-line {
  width: 80px;
  height: 2px;
  display: block;
  margin-top: 10px;
  background: #002733;
}

.quiz-swipper-progress-line span {
  height: 2px;
  display: block;
  background: #D6A600;
  width: 0px;
}

.quiz-swipper-progress-line.step_1 span {
  width: 10%;
}

.quiz-swipper-progress-line.step_2 span {
  width: 20%;
}

.quiz-swipper-progress-line.step_3 span {
  width: 30%;
}

.quiz-swipper-progress-line.step_4 span {
  width: 40%;
}

.quiz-swipper-progress-line.step_5 span {
  width: 50%;
}

.quiz-swipper-progress-line.step_6 span {
  width: 60%;
}

.quiz-swipper-progress-line.step_7 span {
  width: 70%;
}

.quiz-swipper-progress-line.step_8 span {
  width: 80%;
}

.quiz-swipper-progress-line.step_9 span {
  width: 90%;
}

.quiz-swipper-progress-line.step_10 span {
  width: 100%;
}
.quiz-swipper-slide:not(.swiper-slide-active) .quiz-swipper-slide-ovelay {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(270deg, #002733 7.06%, rgba(0, 39, 51, 0.6) 189%);
  z-index: 99;
}

.quiz-swipper-slide:not(.swiper-slide-active) .go_prev_slide,
.quiz-swipper-slide:not(.swiper-slide-active) .go_next_slide {
  display: none;
}

.hero-content--arvssnurran {
  padding: 350px 0 0;
}

.lawyer-section-arvssnurran {
  padding: 40px 0;
  .dual-card{
    z-index: 0;
  }
}

.quiz-swipper:not(.tab__input) {
  padding-top: 100px;
}

.hero-content__title.title-arvssnurran {
  //font-family: 'PP Right Gothic', 'rawline';
  font-style: normal;
  font-weight: 400;
  font-size: 96px;
  line-height: 90%;
  margin-bottom: 25px;
}

.hero-content__subtitle.subtitle-arvssnurran {
  font-family: 'Inter', 'rawline';
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 100%;
}

.quote-arvssnurran-wrapper--title {
  font-family: 'PP Right Gothic Medium', 'rawline';
  font-style: normal;
  font-weight: 500;
  font-size: 64px;
  line-height: 100%;
  color: #FFFFFF;
  margin-right: 150px;
  padding: 55px 0;
}

.quote-arvssnurran-wrapper--subtitle {
  font-family: 'Inter', 'rawline';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #FFFFFF;
}

.quiz-swipper-content-text {
  font-family: 'Inter', 'rawline';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #002733;
  margin-bottom: 30px;
}

.quiz-swipper-slide.last_step.quiz-swipper-user-form {
  background: #D6A600;
  box-shadow: 0px 18px 21px 11px rgba(0, 0, 0, 0.2);
  display: block;
  padding: 0;
  height: 100%;
  //max-height: 600px;
}

.quiz-swipper-content-image{
  img{
    margin-bottom: 15px;
    width: 100%;
    max-width: 200px;
    @media (max-width: 768px){
      max-width: 150px;
    }
  }
}

.quiz-swipper-slide.last_step.quiz-swipper-user-form {
  .quiz-swipper-content {
    background: #fff;
    box-shadow: 0px 18px 21px 11px rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    bottom: -20px;
    position: relative;
    padding: 25px 30px;
    margin: 0 60px;
    width: auto;
    @media (max-width: 525px){
      padding: 15px;
      bottom: -5px;
    }
    .quiz-swipper-content-image{
      margin: 0 0 10px 0;
      display: flex;
      flex-direction: column;
      text-align: left;
      @media (max-width: 525px){
        margin: 0;
      }
      img{
        max-width: 188px;
        margin: 0 auto 5px;
        @media (max-width: 525px){
          max-width: 120px;
        }
      }
    }
  }
}

.quiz-swipper-slide.last_step.quiz-swipper-user-form .quiz-swipper-step {
  display: none;
}

.quiz-swipper-content-right-text{
  p{
    @media (max-width: 525px){
      line-height: 1.3;
      font-size: 14px;
    }
  }
  ul{
    padding-left: 25px;
    text-align: left;
    list-style-type: decimal;
    font-family: 'Inter', 'rawline';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #002733;
    li{
      margin-bottom: 10px;
    }
  }
}

.quiz-swipper-content-bottom-text{
  padding: 0 64px;
  text-align: left;
  font-family: 'Inter', 'rawline';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 125%;
  color: #002733;
  margin-bottom: 18px;
}

.legal-service-content__step.submit{
  padding: 12px 30px;
  font-size: 14px;
  line-height: 150%;
  color: #fff;
  text-transform: uppercase;
  border: none;
  background: #D6A600;
  border-radius: 63px;
  font-weight: 700;
  font-family: 'Inter','rawline';
  height: 48px;
  text-align: center;

  &:hover, &:disabled{
    background-color: rgba(0,39,51,.76) !important;
    border: none !important;
  }
}

.back-to-edit-email{
  padding: 12px 40px;
  font-size: 14px;
  line-height: 150%;
  background: #FFFFFF;
  color: #002733;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1);
  border-radius: 63px;
  font-weight: 700;
  text-transform: uppercase;
  border: 2px solid #fff;

  margin: 0 20px 0 0;

  &:hover{
    color: #d6a600;
    border: 2px solid #d6a600;
  }
  @media (max-width: 475px) {
    margin: 0;
  }
}
.quiz-swipper_footer {
  position: relative;
  margin: 20px 0 5px;
  @media (max-width: 525px){
    margin: 10px 0 5px;
  }
}

.quiz-swipper_footer-title {
  font-style: normal;
  font-weight: 500;
  font-size: 64px;
  line-height: 100%;
  font-family: 'PP Right Gothic Medium', 'rawline';
  color: #fff;
  display: block;
  margin-bottom: 0px;
}

.quiz-swipper_footer-text {
  font-family: 'Inter', 'rawline';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #fff;
  display: block;
}

.tab__subtitle.tab__subtitle-arvssnurran {
  font-family: 'Inter', 'rawline';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #002733;
  margin-bottom: 25px;
  display: block;
  margin-top: 28px;
}

.lawyer-section-arvssnurran {
  background-image: url("/wp-content/themes/fenix4/dist/images/quiz_images/quiz_slider_background1.png");
  background-repeat: no-repeat;
  background-position: center;
	overflow-x: hidden;
}

.quiz-swipper {

	&--home {
		background-image: unset;
		.quote-arvssnurran-container--title {
			display: none;
		}
	}
}



@media (max-width: 525px){
  .quiz-swipper-slide{
    padding: 70px 25px 20px 25px;
  }

  .quiz-swipper-slide .go_next_slide {
    width: 50px;
    height: 50px;
    right: 4%;
    top: 50%;
  }

  .quiz-swipper-slide .go_next_slide:after {
    width: 25px;
    height: 14px;
  }

  .quiz-swipper-slide .go_prev_slide {
    left: 11%;
    top: 50%;
    width: 50px;
    height: 50px;
  }

  .quiz-swipper-slide .go_prev_slide:after {
    width: 25px;
    height: 14px;
  }

  .quiz-swipper-content-buttons button {
    min-width: 110px;
    text-align: center;
    display: block;
  }

  .quiz-swipper-slide.last_step .quiz-swipper-content-buttons {
    flex-direction: column;
  }

  .quiz-swipper-content-buttons button.questionList_confirm {
    margin-bottom: 10px;
  }
  .quiz-swipper-step {
    left: 20px;
    top: 5px;
  }

  .quiz-swipper-content-buttons {
    max-width: 270px;
    margin: auto;
  }

  .quiz-swipper-slide {
    max-height: 400px;
    height: auto;
  }

  .quiz-swipper:not(.tab__input) {
    padding-top: 40px;
  }
}

@media (min-width: 1200px){
  .hero-content--arvssnurran {
    padding: 350px 0 0;
  }

  .hero-content--arvssnurran .hero-content__heading {
    padding: 0 0 70px 3%;
    max-width: 1100px;
  }
}

@media (max-width: 768px){
  .quiz-swipper-content-text {
    font-size: 14px;
    margin-bottom: 25px;
    line-height: 1.3;
  }

  .quiz-swipper-content-heading {
    //margin-bottom: 5px;
  }

  .quiz-swipper-content-buttons button.questionList_confirm,
  .quiz-swipper-content-buttons button.questionList_reload {
    font-size: 18px;
  }

  .quiz-swipper-slide {
    max-height: initial;
    height: 450px;
  }

  .quiz-swipper-slide .go_next_slide {
    width: 36px;
    height: 36px;
    right: -18px;
  }

  .quiz-swipper-slide .go_next_slide:after {
    width: 14px;
    height: 8px;
  }


  .quiz-swipper-slide .go_prev_slide {
    left: 2px;
    width: 36px;
    height: 36px;
  }

  .quiz-swipper-slide .go_prev_slide:after {
    width: 14px;
    height: 8px;
  }

  .quote-arvssnurran-container--title {
    padding: 15px 25px 0;
  }

  .quote-arvssnurran-wrapper--title {
    margin-right: 0;
  }
}


@media (min-width: 320px){
  .first_slide-title {
    font-size: 36px;
  }

  .first_slide-subtitle {
    margin-bottom: 40px;
  }
}

@media (min-width: 720px) {
  .first_slide-title{
    font-size: 48px;
    line-height: 1;
  }

  .first_slide-subtitle {
    margin-bottom: 80px;
  }
}

@media (min-width: 1200px) {
  .first_slide-title {
    font-size: 64px;
  }
}

@media (max-width: 720px) {
  .hero-content__title.title-arvssnurran {
    font-size: 36px;
    margin-bottom: 0.5rem;
    line-height: 1;
  }

  .hero-content__subtitle.subtitle-arvssnurran {
    font-size: 18px;
  }

  .hero-content.hero-content--arvssnurran {
    padding: 350px 0 0;
  }

  .hero-content--arvssnurran {
    padding: 260px 25px 0;
  }

  .hero-content--arvssnurran .hero-content__heading {
    padding: 0 25px 30px;
    font-size: 16px;
  }

  ul#tabList li {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .quiz-swipper-content-bottom-text{
    padding: 0 25px;
  }
}

.hero-content--arvssnurran .hero-content__heading {
  padding: 0 15px 25px;
}

.quiz-swipper.tab__input {
  width: 100%;
  margin-bottom: 18px;
  padding-left: 50px;
  @media (max-width: 525px){
    margin-bottom: 15px;
  }
}

#tpw-append{
  margin-top: 20px;
  @media (max-width: 525px){
    margin-top: 15px;
  }
}

@media (min-width: 320px) {
  .quiz-swipper-content-buttons button.questionList_confirm,
  .quiz-swipper-content-buttons button.questionList_reload {
    min-width: 90px
  }
}

@media (max-width: 600px) {
  .quiz-swipper-content-buttons button.questionList_confirm,
  .quiz-swipper-content-buttons button.questionList_reload {
    padding: 5px 1em;
  }

  .quiz-swipper-content-heading {
    font-size: 18px;
  }
}

@media (max-width: 575px){
  .quiz-swipper-content-buttons button.legal-service-content__step.email {
    margin-bottom: 0;
  }

  .quiz-swipper_footer-title {
    font-size: 32px;
  }

  .quiz-swipper-slide.last_step.quiz-swipper-user-form {
    height: auto;
    //padding-bottom: 30px;
  }

  .quiz-swipper.tab__input {
    height: 48px;
  }

  .quiz-swipper_footer-text {
    padding-left: 15px;
    padding-right: 15px;
    display: block;
  }

}

.quiz-swipper-user-form .go_prev_slide,
.quiz-swipper-user-form .go_next_slide{
  display: none !important;
}
.close_popup{
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
  display: none;
  @media (max-width: 520px){
    top: 22px;
    right: 22px;
  }
}

.overflow_hidden{
  overflow-y: hidden;
}

/*QUIZ MODAL POPUP*/

#quiz-swipper-modal{
  position: fixed;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 100%;
  max-width: 100%;
  height: 100%;
  z-index: 9999;
  bottom: 0;
  display: none;
  left: 0;
  right: 0;
  background: #002733 url('/wp-content/themes/fenix4/dist/images/quiz_images/quiz_slider_background1.png') no-repeat center center;
  background-size: cover ;
}

.quiz-swipper-modal-logo{
  text-align: center;
  padding: 40px 0;
  @media (max-width: 525px){
    display: none;
  }
}

.quiz-swipper-modal-content{
  .quiz-first_slide{
    opacity: 0;
  }
  .close_popup{
    display: block;
  }
  .quote-container{
    display: none;
  }
  @media (max-width: 525px){
    margin-top: 30px;
  }
}

.open_quiz_popup {
  background: #FFFFFF;
  border-radius: 63px;
  padding: 12px 20px 12px 30px;
  font-family: 'Inter', 'rawline';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  text-align: center;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #002733;
  cursor: pointer;
  margin-left: 50px;
  img {
    position: relative;
    top: -2px;
  }
}

.quiz-swipper-modal-footer{
  text-align: center;
  margin-bottom: 40px;
  a{
    display: inline-block;
    background: #D6A600;
    border-radius: 63px;
    outline: none;
    border: none;
    padding: 12px 30px;
    font-family: 'Inter', 'rawline';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 150%;
    color: #002733;
    cursor: pointer;
    &:first-of-type{
      margin-right: 10px;
      @media (max-width: 525px){
        margin-right: 0;
        margin-bottom: 10px;
      }
    }
    &:hover{
      background-color: #a07b01;
      text-decoration: none;
      color: #fff;
    }
    &.btn-blue--phone{
      font-size: 14px;
      padding: 12px 30px 12px 55px !important;
      font-weight: 700;
      line-height: 150%;
      background-color: #465BC4;
      border-color: #465BC4;
      text-transform: uppercase;
      margin: 0 !important;
      min-width: auto !important;
      width: auto !important;
      span:before{
        width: 20px !important;
        height: 20px !important;
        background-size: 20px 20px !important;
        top: -1px !important;
        left: -30px !important;
      }
      &:hover{
        background-color: #374698 !important;
      }
    }
  }
}

@media (max-width: 720px){
  .quiz-swipper-slide.last_step.quiz-swipper-user-form .quiz-swipper-content{
    height: auto;
    margin: auto;
  }

  .quiz-swipper-slide.last_step.quiz-swipper-user-form{
    max-height: inherit;
  }
}

@media (max-width: 475px){
  .quiz-swipper-content-bottom-text{
    padding: 0 10px;
  }

  .quiz-swipper_footer-text{
    margin-bottom: 10px;
  }
}

.quiz-swipper-user-form .close_popup{
  display: none !important;
}

.confirmation-block{
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
  z-index: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  top: 0;
  left: 0;
  border-radius: 15px;
  padding: 20px;
  &>div{
    margin-bottom: 20px;
    text-align: center;
    @media (max-width: 525px){
      margin-bottom: 10px;
    }
  }
  p{
    margin-bottom: 10px;
    @media (max-width: 525px){
      line-height: 1.3;
      font-size: 14px;
    }
  }
  h3{
    margin-bottom: 20px;
  }
  &__image{
    img{
      max-width: 188px;
      margin: 0 auto 5px;
      @media (max-width: 525px){
        max-width: 120px;
      }
    }
  }
  .buttons-wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;

    @media (max-width: 475px){
      flex-direction: column;
      &>button{
        margin-bottom: 10px;
      }
      .legal-service-content__step{
        height: auto;
        padding: 10px 30px;
        border: none;
      }
    }
  }
}

.quiz-swipper-email-text{
  text-align: left;
  font-size: 14px;
  line-height: 1.3;
  margin-bottom: 20px;
  @media (max-width: 525px){
    font-size: 12px;
    margin-bottom: 10px;
  }
}